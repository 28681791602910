













































import Vue, { PropType } from "vue";
import AdvancedUploadCare from "@/components/form/AdvancedUploadCare.vue";
import Loader from "@/components/layout/Loader.vue";
import IconButton from "@/components/button/IconButton.vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import { errorToast } from "@/toast";
import { UploadcareUploadType } from "@/enum";

export default Vue.extend({
  name: "FileUpload",
  components: { AdvancedUploadCare, IconButton, FieldGroup, Loader },
  props: {
    value: {
      type: String,
      default: ""
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "logo-uploader"
    },
    label: {
      type: String,
      default: "Logo"
    },
    isAvatar: {
      type: Boolean,
      default: false
    },
    type: {
      type: String as PropType<UploadcareUploadType>,
      default: UploadcareUploadType.workspace
    }
  },
  watch: {
    value: {
      handler(val) {
        this.logo = val;
      },
      immediate: true
    }
  },
  data() {
    return {
      logo: null as string | null,
      isLoading: false
    };
  },
  computed: {
    uploadType(): string {
      return this.isAvatar ? "avatar" : "company";
    },
    uploadcarePublicKey(): string | undefined {
      switch (this.type) {
        default:
        case UploadcareUploadType.avatar:
          return process.env.VUE_APP_UPLOADCARE_PUBLIC_KEY_AVATARS;
        case UploadcareUploadType.workspace:
          return process.env.VUE_APP_UPLOADCARE_PUBLIC_KEY_WORKSPACES;
      }
    },
    state(): boolean {
      return this.v.$dirty && this.v.$error;
    }
  },
  methods: {
    errorToast,
    onSuccess(e: { uuid: string; name: string }) {
      const extension = e.name.split(".").pop() || "";
      this.isLoading = false;
      this.logo = this.getUrl(e.uuid, extension);
      this.$emit("input", this.getUrl(e.uuid, extension));
      this.v.$reset();
    },
    onReset() {
      this.logo = null;
      this.$emit("input", null);
      this.v.$reset();
    },
    cleanName(name: string) {
      return name.replace(/-/g, "").replace(/[ ()]/g, "");
    },
    getUrl(uuid: string, extension: string) {
      switch (this.type) {
        default:
        case UploadcareUploadType.avatar:
          return `${process.env.VUE_APP_S3_UPLOAD_AVATARS}/${uuid}/${uuid}.${extension}`;
        case UploadcareUploadType.workspace:
          return `${process.env.VUE_APP_S3_UPLOAD_WORKSPACES}/${uuid}/${uuid}.${extension}`;
      }
    },
    onImgSelection() {
      this.isLoading = true;
    },
    onImgError() {
      this.isLoading = false;
    }
  }
});
